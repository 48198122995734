import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Footer extends Component {
    render() {
        return (
            <div className="jamms-bg-light">
                <div className="container border-top py-5">
                    <div className="row mt-4">
                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                SITEMAP
                            </p>
                            <Link to='/' className='jamms-link'>
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                    Home
                                </p>
                            </Link>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://pprqsirau.com/register" className='jamms-link p-0 m-0'>
                                    Daftar Online
                                </a>
                            </p>
                            {/* <Link className='jamms-link'>
                                <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                    Portfolio
                                </p>
                            </Link> */}
                           
                           
                        </div>
                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                OUR LOCATION
                            </p>
                           
                                <p className="jamms-font-12 p-0 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                    <a target='_blank' rel="noopener noreferrer"  href="https://goo.gl/maps/d5rxkFr1YzMZHXse9" className='jamms-link p-0 m-0'>
                                    Jl. Masjid Al-Huda, RT. 2, RW. 2, Sirau, Kemranjen, Cijeruk, Sirau, Kec. Banyumas, Kabupaten Banyumas, Jawa Tengah 53194
                                    </a>
                                </p>
                        </div>

                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                CONTACT US
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285113435516" className='jamms-link p-0 m-0'>
                                0851 1343 5516 (Admin 1)
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285643191642" className='jamms-link p-0 m-0'>
                                0856 4319 1642 (Admin 2)
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="mailto:admin@smpmanuda.sch.id" className='jamms-link p-0 m-0'>
                                admin@smpmanuda.sch.id
                                </a>
                            </p>
                            
                        </div>

                        <div className="col-md-3 mt-4 mt-md-0">
                            <p className="jamms-font-14 jamms-secondary m-0">
                                FOLLOW US
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-3 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/official.pprqsirau/" className='jamms-link p-0 m-0'>
                                Instagram
                                </a>
                            </p>
                            <p className="jamms-font-12 font-weight-light jamms-clickable-el text-secondary mt-1 m-0">
                                <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/channel/UCiCKLmUcmKpDfDpdK-Q2lzQ" className='jamms-link p-0 m-0'>
                                Youtube
                                </a>
                            </p>
                            
                            
                        </div>
                        
                    </div>
                    <div className="text-center mt-4 jamms-font-12  text-secondary">
                        © 2020 SMP Manuda. All Rights Reserved
                    </div>
                </div>

            </div>
        )
    }
}

export default Footer
