import React, { Component } from 'react'
import './Supports/Css/Utils.css'
import './Supports/Css/Navbar.css'
import Navbar from './Components/Navbar'
import LandingPage from './Pages/LandingPage'
import Footer from './Components/Footer'
import { BrowserRouter,Route,Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PageNotFound from './Pages/PageNotFound'
import {TITLE,DESCRIPTION} from './Supports/Constants/Wording'

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Helmet>
              <title>{TITLE}</title>
              <meta name="description" content={DESCRIPTION}/>
          </Helmet>
          <Navbar />
          
          <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path='*' component={PageNotFound} />
          </Switch>
           
          <Footer />
        </div>
      </BrowserRouter>
    )
  }
}

export default App
