import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

export class Navbar extends Component {

    state ={
        openToggle : false
    }

    render() {
        return (
            <div className='jamms-bg-light py-4 jamms-fixed jamms-shadow'>
                <div className="container">
                    <div className="row justify-content-between align-items-center px-4">

                        {/* Header Logo */}
                        <div className="jamms-main jamms-logo-header  jamms-font-second jamms-font-30 jamms-clickable-el ">
                            <Link to='/' className='jamms-link'>
                            SMP <span className='jamms-font-14  '>Manuda</span>
                            </Link>
                        </div>

                        {/* Header Items */}
                        <div className="jamms-main jamms-items-header d-none d-md-block">
                            <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285643191642" className='jamms-link p-0 m-0'>
                                <span className='mr-md-3 jamms-clickable-el' >
                                    Kontak
                                </span>   
                            </a>
                            <a href="https://pprqsirau.com/register" target="_blank" rel="noopener noreferrer" className='jamms-link'>
                            <span className='jamms-font-12 jamms-bg-main jamms-clickable-el text-white py-2 px-3 rounded jamms-shadow'> 
                                Daftar Sekarang
                                <FontAwesomeIcon icon={faSignInAlt} className='ml-2' />
                            </span>
                            </a>
                               
                        </div>


                        {/* Header Icons Mobile */}
                        <div className='jamms-main jamms-items-mobile d-md-none'>
                            {
                                this.state.openToggle ?
                                <span onClick={ () =>this.setState({openToggle : false})} className='jamms-clickable-el'>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </span> 
                                :
                                <span onClick={ () =>this.setState({openToggle : true})} className='jamms-clickable-el'>
                                    <FontAwesomeIcon icon={faBars}/>
                                </span> 

                            }
                        </div>


                    </div>


                    {/* Header Items Mobile */}
                    {
                        this.state.openToggle ?
                            <div className="jamms-main jamms-header-items-mobile px-2 d-md-none">
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285643191642" className='jamms-link p-0 m-0'>
                                    <span className='mr-md-3 jamms-clickable-el' >
                                        Kontak
                                    </span>   
                                </a>
                                
                                
                                {/* <Link to='/pricelist' className='jamms-link'> */}


                                <div className="mt-3 jamms-clickable-el">
                                <a href="https://pprqsirau.com/register" target="_blank" rel="noopener noreferrer" className='jamms-link'>
                                <span className='jamms-font-12 jamms-bg-main jamms-clickable-el text-white py-2 px-3 rounded jamms-shadow'> 
                                    Daftar Sekarang
                                    <FontAwesomeIcon icon={faSignInAlt} className='ml-2' />
                                </span>
                                </a>
                                </div>
                                {/* </Link> */}
                                
                            </div>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}

export default Navbar
