export const TITLE = "SMP Ma'arif NU 2 Sirau Banyumas"
export const SHORT_TITLE = "SMP Manuda"
export const ALIAS = "Manuda"
export const DESCRIPTION = "Sekolah berbasis pesantren terbaik !!"

export const PROGRAM_UNGGULAN = [
  {name : 'Tahfidz (Hafalan Juz 30)', image : require('./../Images/koran.png')},
  {name : 'Kajian Kitab Kuning', image : require('./../Images/quran.png')},
  {name : 'Percakapan Bahasa Inggris & Arab', image : require('./../Images/conversation.png')},
  {name : 'Sablon (Kertas, Plastik & Kain)', image : require('./../Images/tshirt.png')},
  {name : 'Menjahit', image : require('./../Images/sewing-machine.png')},
  {name : 'Musik & Taekwondo', image : require('./../Images/dumbbell.png')},
]