import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import NotFound from './../Supports/Images/maintenance.png'
export class PageNotFound extends Component {
    render() {
        return (
            <div className='py-5'>
                <div className="py-5">
                    <div className="py-5">
                        <div className="container">
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-4 col-6 ">
                                    <img src={NotFound} width='100%' alt="not found"/>
                                </div>
                            </div>
                            <h1 className='text-center mt-4'>OOOOPSS!!</h1>
                            <h4 className='text-center font-weight-light jamms-main'>Page Not Found</h4>
                            <div className='text-center'>
                                <Link to='/' className='font-weight-light jamms-font-12'>Back To Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageNotFound
