import React, { Component } from 'react'
import './../Supports/Css/LandingPage.css'
import Keunggulan from './../Supports/Images/top-rated.png'
import Logo from './../Supports/Images/logo-manuda.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {    faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { DESCRIPTION, PROGRAM_UNGGULAN, TITLE } from '../Supports/Constants/Wording'
import ModalImage from "react-modal-image";


export class LandingPage extends Component {
    state = {
        activePackage : 0
    }
    render() {
        return (
            
            <div>
                {/* Jumbotron */}
                <div className='jamms-bg-secondary jamms-jumbotron p-5'>
                    <div className="container h-100">
                        <div className="row mt-5 pt-5">
                            <div className="col-12 text-center ">
                                <div className='bg-white d-inline-block p-2 jamms-shadow rounded-circle'>
                                    <img src={Logo} alt='logo' width='100px' />
                                </div>
                                <h1 className='jamms-light jamms-font-second jamms-font-70 m-0 p-0 mt-4'>{TITLE}</h1>
                                <p className='jamms-light m-0 p-0'>{DESCRIPTION}</p>
                                    <a href="https://pprqsirau.com/register" target="_blank" rel="noopener noreferrer" className='jamms-link'>
                                    <span className='d-inline-block mt-4 jamms-font-12 jamms-bg-secondary jamms-clickable-el text-white py-2 px-3 rounded jamms-shadow'> 
                                        Daftar Sekarang
                                        <FontAwesomeIcon icon={faSignInAlt} className='ml-2' />
                                    </span>
                                    </a>
                                   
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* Why Order With Us */}
                <div className='py-5 bg-light'>
                <iframe width="560" style={{position:'relative',maxWidth:'80vw',left:'50%',transform:'translateX(-50%)',bottom:'25vh',marginBottom:'-18vh',marginLeft:'10px',marginRight:'10px'}} height="325" src="https://www.youtube.com/embed/SC6LlUgmVdo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div className="container">
                        <div className='text-center'>
                            <h1 className='jamms-font-second text-center d-inline'>Program Unggulan !!</h1>
                            <p className='text-secondary'>Kenapa mesti sekolah di Manuda??</p>
                        </div>

                        <div className="row my-5  justify-content-between">
                            <div className="col-md-4  row align-items-center  px-5">
                                {
                                    PROGRAM_UNGGULAN.slice(0,3).map((val) => {
                                        return(
                                            <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                                <div className="col-4 ">
                                                    <img src={val.image} width='100%' alt="order-online"/>
                                                </div>
                                                <div className="col-8 jamms-font-14 font-weight-light">
                                                {val.name}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-4 d-none d-md-block ">
                                <img src={Keunggulan} width='100%' alt="keunggulan-kami"/>
                            </div>
                            <div className="col-md-4  row align-items-center px-5">
                                {
                                    PROGRAM_UNGGULAN.slice(3,7).map((val) => {
                                        return(
                                            <div className='h-25  row mt-3 mt-md-0 border-bottom pb-4 pb-md-0'>
                                                <div className="col-4 ">
                                                    <img src={val.image} width='100%' alt="order-online"/>
                                                </div>
                                                <div className="col-8 jamms-font-14 font-weight-light">
                                                {val.name}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                    </div>
                </div>



                <div ref={(el) => { this.priceList = el; }} className='jamms-package-sablon-container'>
                    <div className="container py-5">
                        <div className='text-center'>
                            <h1 className='jamms-font-second jamms text-center d-inline'>Galery</h1>
                            <p className='text-secondary'>Keseruan aktifitas siswa siswi Manuda !!</p>
                            <div className='row-ig'>
                                <div className='column-ig'>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_1.jpg')} large={require('./../Supports/Images/image_1.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_2.jpg')} large={require('./../Supports/Images/image_2.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_3.jpg')} large={require('./../Supports/Images/image_3.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_4.jpg')} large={require('./../Supports/Images/image_4.jpg')} alt="galery"/>
                                    </div>
                                </div>
                                <div className='column-ig'>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_5.jpg')} large={require('./../Supports/Images/image_5.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_6.jpg')} large={require('./../Supports/Images/image_6.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_17.jpg')} large={require('./../Supports/Images/image_17.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_8.jpg')} large={require('./../Supports/Images/image_8.jpg')} alt="galery"/>
                                    </div>
                                </div>
                                <div className='column-ig'>
                                     <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_9.jpg')} large={require('./../Supports/Images/image_9.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_10.jpg')} large={require('./../Supports/Images/image_10.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_11.jpg')} large={require('./../Supports/Images/image_11.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_12.jpg')} large={require('./../Supports/Images/image_12.jpg')} alt="galery"/>
                                    </div>
                                </div>
                                <div className='column-ig'>
                                     <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_13.jpg')} large={require('./../Supports/Images/image_13.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_14.jpg')} large={require('./../Supports/Images/image_14.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_15.jpg')} large={require('./../Supports/Images/image_15.jpg')} alt="galery"/>
                                    </div>
                                    <div class="img-class">
                                        <ModalImage small={require('./../Supports/Images/image_16.jpg')} large={require('./../Supports/Images/image_16.jpg')} alt="galery"/>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>


                {/* Subsrciption Email & Login */}

                <div className="jamms-bg-light py-5 px-3">
                    <div className="container jamms-banner-subscription text-center jamms-light py-5">
                        <div className="row justify-content-center">
                            <div className="col-8 col-md-4">
                                <h3 className='p-0 m-0'>Masih Ragu??</h3>
                                <p className='p-0 m-0 font-weight-light mt-3'>Yuk tanya kami biar semakin yaqueen !!</p>
                                <a target='_blank' rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=+6285643191642" className='jamms-link p-0 m-0'>
                                    <button className='jamms-button-light jamms-clickable-el jamms-shadow mt-4'> Tanya admin</button>
                                </a>
                                


                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}

                

            </div>
        )
    }
}

export default LandingPage
